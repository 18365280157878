@import url("https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css");

.gallery-header {
  background-color: #e0e0e0;
  min-height: 100vh;
  /*display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;*/
  font-size: calc(10px + 2vmin);
  color: white;
}
